import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CFormSelect } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/orders';

const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));

const status = [
    { value: 'created', label: 'Створено клієнтом' },
    { value: 'aproved', label: 'Підтверджено' },
    { value: 'comlated', label: 'Виконано' }
]
const paymentMethod = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'cash', label: 'Готівка' },
    { value: 'bank', label: 'Банківський переказ' },
    { value: 'bank', label: 'Мульти оплата' }
]
const curency = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'uah', label: 'uah' },
    { value: 'usd', label: 'usd' },
    { value: 'eur', label: 'eur' }
]

const objOrderData = {
    account: '',
    agreement: '',
    status: 'aproved',
    description: '',
    dateOrder: null,
    curency: 'uah',
    paymentMethod: 'cash',
    sumOrdered: 0,
    curencyRate: 1,
    discountTotal: 0,
    discountPercent: 0,
    // shippingAddressStreet: '',
    shippingAddressCity: '',
    // shippingAddressBuilding: '',
    // shippingAddressCountry: 'Україна',
    shippingAddressDate: '',
    synced: false,
    manager: ''
};

const FormOrders = (props) => {
    // console.log("formOrders props", props);

    const editorRef = useRef(null);
    const data = props.data;
    const navigate = useNavigate();
    const [values, setValues] = useState(objOrderData);
    const [selectItems, setSelectItems] = useState({
        account: {},
        status: status[1],
        paymentMethod: paymentMethod[0],
        curency: curency[0],
    });

    // Контакти вибраного контрагента
    const [contacts, setContacts] = useState(false);
    // Адреса(и) вибраного контрагента
    const [address, setAddress] = useState("");
    // Вибрана адреса контрагента
    const [selectedAddress, setSelectedAddress] = useState("");
    // Наппечатана адреса
    const [printedAddress, setPrintedAddress] = useState("");
    // Ввести адресу вручну чи взяти з контрагента
    const [checked, setChecked] = useState(false);
    // Текст для popup
    const popupTextSuccess = "Замовлення створено";

    // Вибір адреси, яка буде додана до замовлення (від контрагента або введена вручну)
    useEffect(() => {
        if (checked && printedAddress != "") {
            // адреса, введена вручну
            setValues({
                ...values,
                shippingAddressCity: printedAddress
            });
        }
        if (!checked && selectedAddress != "") {
            // адреса, вибрана з даних контрагента
            setValues({
                ...values,
                shippingAddressCity: selectedAddress.value
            });
        };
    }, [selectedAddress, printedAddress, checked]);

    // Коли вибраний контрагент, то взяти в нього всі контакти
    useEffect(() => {
        // Вибраний контрагент 
        if (selectItems.account.value != undefined) {
            console.log("selectItems.account.value", selectItems.account.value);
            let selected = defaultAccountManager.filter((el) => el.value == selectItems.account.value);
            // console.log("selected", selected);
            // Всі контакти вибраного контрагента
            setContacts(selected);
        };
    }, [selectItems.account]);

    // Коли є всі контакти вибраного контрагента, то взяти тільки контакт типу "address"
    useEffect(() => {
        if (contacts != false && contacts != undefined) {
            // console.log("contacts", contacts);
            let addressType = contacts[0].contacts.filter((el) => el.type === "address");
            // console.log("addressType", addressType);
            setAddress(addressType);
        };
    }, [contacts]);

    // Функція для вибору адреси з options
    const handleSelectChangeAddress = (event) => {
        const { value } = event.target.options[event.target.selectedIndex];
        setSelectedAddress({ value });
    };

    useEffect(() => {
        if (data != null) {
            setValues({
                ...values,
                account: `/api/accounts/${data.account.id}`,
                agreement: (data.agreement != undefined) ? `/api/agreements/${data.agreement.id}` : '',
                status: data.status,
                description: data.description,
                dateOrder: data.dateOrder,
                curency: data.curency,
                paymentMethod: data.paymentMethod,
                sumOrdered: data.sumOrdered,
                curencyRate: data.curencyRate,
                discountTotal: data.discountTotal,
                discountPercent: data.discountPercent,
                shippingAddressStreet: data.shippingAddressStreet,
                shippingAddressCity: data.shippingAddressCity,
                shippingAddressBuilding: data.shippingAddressBuilding,
                shippingAddressCountry: data.shippingAddressCountry,
                shippingAddressDate: data.shippingAddressDate,
                synced: false,
            });
            let s = status.map((v, k) => {
                if (data.status == v.value)
                    return v;
            });
            let pm = paymentMethod.map((v, k) => {
                if (data.paymentMethod == v.value)
                    return v;
            });
            let c = curency.map((v, k) => {
                if (data.curency == v.value)
                    return v;
            });
            setSelectItems({
                ...selectItems,
                account: {
                    label: data.account.name,
                    value: data.account.id,
                    contacts: data.account.contacts
                },
                agreement: {
                    label: data.agreement.name,
                    value: data.agreement.id,
                },
                status: s,
                paymentMethod: pm,
                curency: c,
            });
        } else {
            setValues({
                ...values,
                manager: (typeof userData == 'object' && userData.id != undefined) ? `/api/users/${userData.id}` : ''
            });
        }
    }, [data]);

    const handleInputChange = (e) => {
        // console.log(e);
        const { name, value, type } = e.target;
        setValues({
            ...values,
            [name]: type === "number" ? parseFloat(value, 10) : value
        });
    };

    // Ф-ція для зміни адреси, введеної вручну
    const handleInputAddressChange = (event) => {
        setPrintedAddress(event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        let v = true;
        let vError = '';

        if (values.account == '') {
            v = false;
            vError += "-> Контрагент\n";
        }
        if (values.agreement == '') {
            v = false;
            vError += "-> Договір\n";
        }
        if (values.status == '') {
            v = false;
            vError += "-> Статус\n";
        }
        if (values.paymentMethod == '') {
            v = false;
            vError += "-> Метод оплати\n";
        }
        if (values.curency == '') {
            v = false;
            vError += "-> Валюта\n";
        }

        if (!v) {
            alert("Ви не заповнили обовязкові поля:\n" + vError);
        }

        if (v && editorRef.current) {
            console.log(editorRef.current.getContent());
            setValues({
                ...values,
                ['desciption']: editorRef.current.getContent(),
                // ['account']: (typeof values.account != 'string')? `/api/accounts/${values.account}`: values.account
            });
            values.account = (typeof values.account != 'string') ? `/api/accounts/${values.account}` : values.account;
            values.agreement = (typeof values.agreement != 'string') ? `/api/agreements/${values.agreement}` : values.agreement;
            values.manager = (typeof userData == 'object' && userData.id != undefined) ? `/api/users/${userData.id}` : ''
            // const article = values;

            if (data != undefined) {
                const response = AxiosInstance.put(`${urlApi}/${data.id}`, values);
            } else {
                const response = AxiosInstance.post(urlApi, values).then((response) => {
                    if (response.status == 201) {
                        // Popup про успішні зміни
                        props.toastifySuccess(popupTextSuccess);
                        return setTimeout(() => {
                            props.setShow(true);
                            return navigate(`/orders/update/${response.data.id}`);
                        }, 1000);
                    }
                });
            }
        }
    }

    // Контрагенти, які приписані до менеджера
    const [defaultAccountManager, setDefaultAccountManager] = useState({});
    // Договори, які приписані до контрагента
    const [defaultAagreement, setDefaultAagreement] = useState({});
    useEffect(() => {
        loadOptionsAcc('');
    }, []);
    // useEffect(() => {
    //     console.log("defaultAccountManager", defaultAccountManager);
    // }, [defaultAccountManager]);


    /** Контрагенти */
    // const [selectedOption, setSelectedOption] = useState(null);
    const loadOptionsAcc = async (inputValue) => {
        let query = `name=${inputValue}`;
        if (inputValue == '' && role == 'ROLE_MANAGER')
            query = `manager.id=${userData.id}`;
        if (inputValue != '' && role == 'ROLE_MANAGER')
            query += `&manager.id=${userData.id}`;
        if (inputValue == '' && role == 'ROLE_ADMIN')
            return {};

        let rez = await AxiosInstance.get(`/accounts?${query}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
                contacts: result.contacts
            }));
        });
        setDefaultAccountManager(rez);
        return rez;
    };

    useEffect(() => {
        if (selectItems.account != undefined && selectItems.account.value != undefined && selectItems.account.value != '')
            loadOptionsAgree('');
    }, [selectItems])

    // Завантажити договори
    const loadOptionsAgree = async (inputValue) => {
        let query = `name=${inputValue}`;
        if (inputValue == '' && role == 'ROLE_MANAGER')
            query = `account.manager=${userData.id}`;
        if (selectItems.account != undefined && selectItems.account.value != undefined) {
            query = `&account.id=${selectItems.account.value}`;
        }

        let rez = await AxiosInstance.get(`/agreements?${query}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
        setDefaultAagreement(rez);
        return rez;
    };

    const handleChange = (value, name) => {
        setValues({
            ...values,
            [name]: value.value
        });
        setSelectItems({
            ...selectItems,
            [name]: value
        });
    };

    const datePicker = (value) => {
        let formattedDate = value.toISOString().slice(0, 10);
        console.log(typeof formattedDate);
        // values['dateOrder'] = formattedDate;
        let name = 'dateOrder';
        setValues({
            ...values,
            [name]: formattedDate
        });
        console.log(values);
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsAcc}
                            // defaultOptions
                            value={selectItems.account}
                            // onChange={handleChange}
                            className="flex-grow-1"
                            name="account"
                            defaultOptions={defaultAccountManager}
                            onChange={(value) => { handleChange(value, 'account') }}
                            classNamePrefix='account'
                            styles={{ border: '1px solid red' }}
                        />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Договір</CFormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsAgree}
                            // defaultOptions
                            value={selectItems.agreement}
                            // onChange={handleChange}
                            className="flex-grow-1"
                            name="account"
                            defaultOptions={defaultAagreement}
                            onChange={(value) => { handleChange(value, 'agreement') }}
                            classNamePrefix='account'
                            styles={{ border: '1px solid red' }}
                        />
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col'>

                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Статус замовлення</CFormLabel>
                        <Select options={status} value={selectItems.status} onChange={(value) => { handleChange(value, 'status') }} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Метод оплати</CFormLabel>
                        <Select options={paymentMethod} value={selectItems.paymentMethod} onChange={(value) => { handleChange(value, 'paymentMethod') }} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Дата виконання</CFormLabel>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                                value={values.dateOrder} 
                                onChange={datePicker}  
                                inputFormat="yyyy-MM-dd"  
                                // renderInput={(params) => <TextField {...params} />} 
                            />
                        </LocalizationProvider> */}
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={(values.dateOrder == null) ? new Date() : new Date(values.dateOrder)}
                            onChange={(date) => datePicker(date)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Сума</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='sumOrdered' disabled={true} value={values.sumOrdered} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Курс</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='curencyRate' value={values.curencyRate} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1" name='curency' value={values.curency} onChange={handleInputChange}>Валюта</CFormLabel>
                        <Select options={curency} value={selectItems.curency} onChange={(value) => { handleChange(value, 'curency') }} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Знижка %</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='dateOrder' value={values.dateOrder} onChange={handleInputChange} />
                    </div>
                </div>

                <div className='row mt-3'>
                    <div>
                        <div className='col'>
                            <CFormCheck type="checkbox" label="Ввести адресу вручну" checked={checked} onChange={() => setChecked(!checked)} />
                        </div>
                        {
                            (!checked) ?
                                <div className='col'>
                                    <CFormLabel htmlFor="exampleFormControlInput1">Адреса контрагента*</CFormLabel>
                                    <CFormSelect id=""
                                        onChange={handleSelectChangeAddress}
                                    // value={(selectedAddress !== "") ? selectedAddress : 0}
                                    //  required
                                    >
                                        <option value="">--Виберіть елемент--</option>
                                        {
                                            (typeof address == "object") &&
                                            address.map(el => {
                                                return <option key={el.id} value={el.value}>{el.value}</option>
                                            })
                                        }
                                    </CFormSelect>
                                </div>

                                :
                                <div className='col'>
                                    <CFormLabel htmlFor="exampleFormControlInput1">Адреса введена вручну*</CFormLabel>
                                    {/* <CFormInput type="address" id="exampleFormControlInput2" name='shippingAddressCity' value={printedAddress} onChange={() => setPrintedAddress} /> */}
                                    <CFormInput type="address" id="exampleFormControlInput2" name='shippingAddressCity' value={printedAddress} onChange={handleInputAddressChange} />
                                </div>
                        }
                    </div>

                    {/* <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Країна</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressCountry' value={values.shippingAddressCountry} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Місто</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressCity' value={values.shippingAddressCity} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Вулиця</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressStreet' value={values.shippingAddressStreet} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Будинок</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressBuilding' value={values.shippingAddressBuilding} onChange={handleInputChange} />
                    </div> */}
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>

                            <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={(values != undefined) ? values.description : ''}
                                init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    );
};

export default FormOrders;