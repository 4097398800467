import { useState, useEffect } from "react";
import { cilSave } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';

// Оновити конкретний продукт, який вже доданий до замовлення
const UpdateProductItem = (props) => {
    // console.log("UpdateProductItem props", props);

    const urlApi = '/order_products';
    const [counter, setCounter] = useState(props.product.count);
    // Зображення товару
    const media = (props.product.product.media != undefined && props.product.product.media.length > 0) ? props.product.product.media : "";
    // Ціна одиниці товару
    const productPrice = (props.product != undefined && props.product.priceForOne != undefined) ? props.product.priceForOne : '0';
    // Ціна введена менеджером
    const [priceManager, setPriceManager] = useState(productPrice);
    // Вхідна ціна одиниці товару
    const productIncomePrice = (props.product != undefined && props.product.priceIncome != undefined) ? props.product.priceIncome : '0';
    // Змінені дані про товар, які будуть додані до замовлення
    const [productData, setProductData] = useState(false);
    // Сума
    // const sum = productPrice * counter;
    const sum = priceManager * counter;
    // Текст для popup
    const popupTextSuccess = "Дані замовленого товару оновлено";

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    };
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let count = (event.target.value === "" || event.target.value < 0) ? 1 : parseFloat(event.target.value);
        setCounter(count);
    };

    // Зміна ціни продукту вручну менеджером
    const priceChange = (event) => {
        // console.log("priceChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let price = (event.target.value === "" || event.target.value < 0) ? 1 : parseFloat(event.target.value);
        setPriceManager(price);
    };

    // Формування даних про товар для додавання до замовлення
    useEffect(() => {
        setProductData({
            "count": counter,
            "priceForOne": priceManager
        });
    }, [counter, priceManager]);

    // Запит на API для оновлення даних про замовлений товар
    const putProductData = () => {
        AxiosInstance.put(`${urlApi}/${props.product.id}`, productData).then((response) => {
            if (response.status === 200) {
                // console.log("response", response);
                // alert("Дані оновлено");
                // Popup про успішні зміни
                props.toastifySuccess(popupTextSuccess);
                // Показати таблицю з доданими до замовлення товарами
                props.setShowTable(true);
                // Для запиту за списком з доданими товарами до замовлення
                props.setGetOP(true);
                // Для можливого повторного відкриття вікна редагування товару
                props.setOpenUpdateProduct(false);
            };
        });
    };

    // Функція для закриття оновлення товару і відкриття модального вікна з списком доданих товарів
    const changeModal = () => {
        // Показати таблицю з доданими до замовлення товарами
        props.setShowTable(true);
        // Для запиту за списком з доданими товарами до замовлення
        props.setGetOP(true);
        // Для можливого повторного відкриття вікна редагування товару
        props.setOpenUpdateProduct(false);
    };

    // Функція для скидання змінених менеджером значень
    const cancel = () => {
        // Скинути кількість товару
        setCounter(props.product.count);
        // Скинути ціну товару
        setPriceManager(productPrice);
    };

    return (
        <div style={{ position: "relative", border: "1px solid #000", padding: "10px", marginTop: "13px" }}>
            <span onClick={changeModal} style={{ cursor: "pointer", backgroundColor: "red", position: "absolute", top: "0", right: "0", height: "25px", width: "25px", border: "1px solid #000", textAlign: "center" }}>X</span>
            <h5>{props.product.name}</h5>

            <div className="row">
                <div className="col-xxl-8 col-xl-6">
                    <Carousel
                        className="me-3" style={{ height: '200px', width: 'auto' }}
                        showArrows={false} showThumbs={true} showStatus={false} thumbWidth={80}
                    >
                        {
                            // Відобразити всі зображення до продукту
                            (media != '') &&
                            media.map(el => {
                                return (
                                    <div key={el.id}>
                                        <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="col-xxl-4 col-xl-6">
                    <div className="orders-update__text">Прихідна ціна: {productIncomePrice} грн.</div>
                    <div className="orders-update__text">
                        <span>Ціна, грн.: </span> <br />
                        <input className='mt-2 me-1 orders-update__input-price' type="number" pattern="[0-9]*" value={priceManager} onChange={priceChange} />
                    </div>
                    <div className="orders-update__text">Сума: {sum} грн.</div>
                    <div className="mt-1 orders-update__text">
                        <span>Кількість, шт.: </span> <br />
                        <div className="mt-2 d-flex">
                            <button className='me-1 orders-update__btn-counter' onClick={productDecrement} >-</button>
                            <input className='text-center orders-update__input-counter' type="number" pattern="[0-9]*" min="1" value={counter} onChange={inputChange} />
                            <button className='ms-1 orders-update__btn-counter' onClick={productIncrement}>+</button>
                        </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-center align-items-center">
                        <button onClick={cancel} title="Скинути внесені зміни">Скинути</button>
                        <CIcon icon={cilSave} customClassName="nav-icon orders-update__icon" height={25} onClick={putProductData} title="Зберегти зміни" />
                    </div>
                </div>
            </div>
        </div >
    )
};

export default UpdateProductItem;