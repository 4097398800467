import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { cilBasket, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Швидку додавання товару
const QuickProductAddition = (props) => {
    // console.log("props", props);

    const urlApi_1 = '/products';
    const urlApi_2 = '/order_products';
    // Чи даний товар є в списку замовлень
    const [added, setAdded] = useState(false);
    // Кількість товару
    const [counter, setCounter] = useState(1);
    const [count, setCount] = useState(1);
    // Вибраний товар
    const [selectedOption, setSelectedOption] = useState(null);
    // Дані про продукт
    const [product, setProduct] = useState(false);
    // Заблокувати кнопку додавання товару, поки не вибраний товар
    const [disabled, setDisabled] = useState(true);
    // Заокруглені значення до 2-х знаків після коми
    const priceForOne = product.price != undefined ? product.price.toFixed(2) : 0;
    const sumPrice = (priceForOne * counter).toFixed(2);
    // Товари, які є в списку замовлення
    const [addedProducts, setAddedProducts] = useState(false);
    // Текст для popup
    const popupTextSuccess = "Товар додано";

    // Функція для запиту order_products - додані товари до замовлення
    const getOrderProducts = () => {
        AxiosInstance.get(`${urlApi_2}?order=${props.data.id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response data", response);
            // Наповнити зписок з доданими товарами для коректного відображення іконки "додати/додано"
            if (response.status === 200) {
                setAddedProducts(response.data);
            };
        });
    };

    // При старті модального вікна завантажити додані до замовлення товари
    useEffect(() => {
        getOrderProducts();
    }, []);

    // Дані про товар, які будуть додані до замовлення
    const productData = {
        "order": `api/orders/${props.data.id}`,
        "name": product.name,
        "product": "/api/products/" + product.id,
        "count": counter,
        "measurementUnit": (product.measurement_unit != undefined) ? `/api/measurment_units/${product.measurement_unit.id}` : null,
        "discount": 0,
        "curency": "uah",
        "priceForOne": parseFloat(priceForOne),
        "sumOrdered": parseFloat(sumPrice)
    };

    // useEffect(() => {
    //     console.log("productData", productData);
    // }, [productData]);

    // Завантаження товарів з API
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get('/products?name=' + inputValue, { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };

    // Функція для вибору товару з списку
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    // Функція для запиту за інформацією про продукт
    const getProduct = () => {
        AxiosInstance.get(`${urlApi_1}/${selectedOption.value}`).then((response) => {
            // console.log("get /api/products/ response", response);
            setProduct(response.data);
        });
    };

    // Коли вибрали товар, то зробити запит за його даними
    useEffect(() => {
        if (selectedOption != null) {
            // console.log("selectedOption", selectedOption);
            getProduct();
        }
    }, [selectedOption]);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    }
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };
    useState(() => {
        setCount(counter);
    }, [counter]);

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let count = event.target.value === "" ? 1 : parseFloat(event.target.value);
        setCounter(count);
    };

    // Коли прийшли дані про вибраний товар, то розблокувати кнопку 
    useEffect(() => {
        // console.log("product", product);
        if (product != false) {
            setDisabled(false);
        }
    }, [product]);

    // Запит на API для додавання товару до замовлення
    const handleAddToOrder = () => {
        if (productData != false) {
            // console.log("productData", productData);

            // Запит на /order_products по orders та product.id (чи такий товар вже дододано до замовлення)
            AxiosInstance.get(`${urlApi_2}?order=${props.data.id}&product.id=${product.id}`).then((response) => {
                if (response.status === 200) {
                    // console.log("response get product.id", response);
                    if (response.data['hydra:totalItems'] === 0) {
                        // Додати товар до списку замовлення
                        AxiosInstance.post(`${urlApi_2}`, productData).then((response) => {
                            if (response.status === 201) {
                                // console.log("response", response);
                                // alert("Товар додано до замовлення");
                                // Popup про успішні зміни
                                props.toastifySuccess(popupTextSuccess);
                                // Оновити список order_products
                                props.setGetOP(true);
                                // Після додавання продукту, закрити модальне вікно
                                props.setShowModal(false);
                            };
                        });
                    };
                    // else {
                    //     alert("Даний товар вже додано");
                    // };
                };
            });
        };
    };

    // Відслідковувати зміну додавання/видалення з кошика замовлень
    useEffect(() => {
        // console.log("addedProducts", addedProducts);
        let isInArray = false;
        // if (props.addedProducts != false) {
        if (addedProducts != false) {
            // props.addedProducts.some(el => {
            addedProducts.some(el => {
                // console.log("el", el);
                // Порівняти id - чи є в кошику замовлень
                if (product != false && el.product.id === product.id) {
                    isInArray = true
                };
            });
        };
        // Для відображення іконок "додати/додано"
        setAdded(isInArray);
    }, [product, addedProducts]);

    // Функція для заакриття модального вікна
    const closeModal = () => {
        props.setShowModal(false);
    };

    return (
        <div style={{ position: "relative" }}>
            <span
                onClick={closeModal} style={{ cursor: "pointer", backgroundColor: "red", position: "absolute", top: "-50px", right: "-20px", height: "25px", width: "25px", border: "1px solid #000", textAlign: "center" }}
            >
                X
            </span>

            <div className="d-flex">
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    value={selectedOption}
                    onChange={handleChange}
                    className="flex-grow-1 me-3"
                />
            </div>
            <div className='mt-3'>
                {
                    (!added) ?
                        // Якщо товар не доданий до замовлення
                        <div>
                            <button className='me-1' onClick={productDecrement}>-</button>
                            <input className='me-1 text-center' type="text" pattern="[0-9]*" value={counter} onChange={inputChange} style={{ width: "40px" }} />
                            <button className='me-2' onClick={productIncrement}>+</button>
                            <button disabled={disabled}>
                                <CIcon icon={cilBasket} customClassName="nav-icon" height={18} onClick={() => handleAddToOrder()} style={{ cursor: "pointer" }} />
                            </button>
                        </div>
                        :
                        // Якщо товар вже доданий до замовлення
                        <div>
                            <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={18} style={{ color: "green" }} />
                            <span className="ms-2">Даний товар вже доданий</span>
                        </div>
                }
            </div>
        </div>
    )
};

export default QuickProductAddition;