import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton, CRow, CCol} from '@coreui/react';
import axios from "axios";
import Select from 'react-select'
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import { Row } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert';
import CIcon from '@coreui/icons-react';
import { cilTrash, cilCopy } from '@coreui/icons';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/users';
const urlRouter = '/users';
const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderUsersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      //console.log('response', response.data.id)
      //user_id = response.data.id;
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}

const UsersForm = () => {
  const [roleList, setRoleList] = useState([
    { value: 'ROLE_ADMIN', label: 'Адміністратор' },
    { value: 'ROLE_MANAGER', label: 'Менеджер' },
    { value: 'ROLE_CLIENT', label: 'Клієнт' },
    { value: 'ROLE_USER', label: 'Без ролі' },
  ]);

  useEffect(() => {
    if (role == 'ROLE_MANAGER')
      setRoleList([{ value: 'ROLE_CLIENT', label: 'Клієнт' }])
  }, []);

  const user_id = useRef();
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [username, setUsername] = useState((data != undefined) ? data.username : '');
  const [plainPassword, setPlainPassword] = useState((data != undefined) ? data.plainPassword : '');
  const [firstName, setFirstName] = useState((data != undefined) ? data.firstName : '');
  const [lastName, setLastName] = useState((data != undefined) ? data.lastName : '');
  const [phone, setPhone] = useState((data != undefined) ? data.phone : '');
  const [email, setEmail] = useState((data != undefined) ? data.email : '');
  const [telegram, setTelegram] = useState((data != undefined) ? data.telegram : '');
  const [viber, setViber] = useState((data != undefined) ? data.viber : '');
  const [workSchedule, setWorkSchedule] = useState((data != undefined) ? data.workSchedule : '');
  const [showModalMedia, setShowModalMedia] = useState(true);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileType ,setFileType] = useState();
  const [fileDescr, setFileDescr] = useState();
  const [validated, setValidated] = useState(false);
  const [isFileSend, setIsFileSend] = useState(false)
  
  const [roles, setRoles] = useState(() => {
    let rez;
    if (data != undefined && data.roles != undefined && data.roles.length > 0) {
      roleList.map((v) => {
        if (v.value == data.roles[0])
          rez = v;
      })
    }
    return rez;
  });
  const popup = MyToastify();
  // console.log(roles);
  // const [address, setAddress] = useState((data != undefined) ? data.address : '');
  // const [status, setStatus] = useState((data != undefined) ? data.status : '');
  // const [codeUser, setCodeUser] = useState((data != undefined) ? data.codeUser : '');
  // const [codeManager, setCodeManager] = useState((data != undefined) ? data.codeManager : '');
  const editorRef = useRef(null);
  
  useEffect(() => {
    if(data != undefined && tableData.length == 0)
      axios.get(process.env.REACT_APP_SERVER_URL + '/api/users_docs', {params:  {'users.id': data.id}}).then(res => {
        setTableData(res.data["hydra:member"]);
        console.log('res1', res.data["hydra:member"]);
      })
  }, [])

  useEffect(() => {
    if(data != undefined)
      axios.get(process.env.REACT_APP_SERVER_URL + '/api/users_docs', {params:  {'users.id': data.id}}).then(res => {
        setTableData(res.data["hydra:member"]);
        console.log('res2', res.data["hydra:member"]);
      })
  }, [isFileSend])

  const updateRows = () => {
    axios.get(process.env.REACT_APP_SERVER_URL + `/api/users_docs?users.id=${data.id}`).then(res => {
      setTableData(res.data["hydra:member"]);
    })
  }

  const handleSubmit = event => {
    event.preventDefault();
    // if (editorRef.current) {
    // console.log(editorRef.current.getContent());
    const data_response = {
      username: username,
      plainPassword: plainPassword,
      firstName: firstName,
      lastName: lastName  ,
      roles: [roles.value],
      // address: address,
      // status: status,
      // codeUser: codeUser,
      // codeManager: codeManager,
      phone: phone,
      email: email,
      telegram: telegram,
      viber: viber,
      workSchedule: workSchedule,
      active: 'on',
    };
    if (data != undefined) {
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response).then((response) => {
        if (response.status === 200)
          return navigate(urlRouter);
      });
    } else {
      const response = AxiosInstance.post(`${urlApi}`, data_response).then((response) => {
        if (response.status === 201)
          return navigate(urlRouter);
      });
    }
    // }
  }

  const handleChangeRole = (value) => {
    setRoles(value);
  };

  const actionShowModalMedia = (rez) => {
    setShowModalMedia(rez);
    console.log(11111);
    if (!rez)
      getMedia();
  };

  const handleSubmitFile = event => {
    event.preventDefault();

    let v = false;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      v = true;
    }
    setValidated(true);
    if (v) {
      const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
      const url2 = process.env.REACT_APP_SERVER_URL + `/api/users_docs?users.id=${data.id}`;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      formData.append('name', fileName);
      formData.append('description', fileDescr);
      
      if (user_id.current != undefined)
        formData.append('user', user_id.current);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${tokenStr}`
        },
      };
      const config2 = {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${tokenStr}`
        },
      };
      axios.post(url, formData, config).then((response) => {
        //console.log(user_id);
        if(response.status == 201){
          
          let article = {
            name: fileName,
            type: fileType,
            description: fileDescr,
            users: `/api/users/${data.id}`,
            media: `/api/media_objects/${response.data.id}`
          }
  
          axios.post(url2, article, config2).then(() => {updateRows()});
        }
      });
    }

  }

  function handleChange(event) {
    setFile(event.target.files[0])
  }

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            console.log('click')
            // const response = axios.delete(process.env.REACT_APP_SERVER_URL + '/api/media_objects/' + row.id, header).then((response) => {
            axios.delete(process.env.REACT_APP_SERVER_URL + '/api/users_docs/' + row.id, header).then((response) => {
              // Оновити таблицю
              updateRows()
              // Popup про успішні зміни
              popup.toastifySuccess('Файл видалено');
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const rows = tableData.current && tableData.current.map(e => {
    return {
      id: e.id,
      col1: e.type,
      col2: e.name,
      col3: e.description
    } 
  }) 
  
  const columns = [
    { field: 'name', headerName: 'Назва', width: 150 },
    { field: 'type', headerName: 'Тип', width: 150 },
    { field: 'description', headerName: 'Опис', width: 250 },
    { 
      field: 'col4', 
      headerName: 'Завантажити', 
      width: 150 ,
      sortable: false,
      renderCell: ({row}) => {
        // console.log('row',row);
        return <a href={`${process.env.REACT_APP_SERVER_URL}${row.media.contentUrl}`} download >файл</a>;
      }
  
    },
    {
      field: "action",
      headerName: "Delete",
      width: 90,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        return <CButton type="submit" color="danger" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    }
  
  ];
  return (
    <>
      {(data != undefined) &&
        <>
          {(data.mediaObjects != undefined && data.mediaObjects.length > 0) && <img src={process.env.REACT_APP_SERVER_URL + data.mediaObjects[0].contentUrl} alt="blog-image" height={300} className='m-5' />}

          <BasicModal
            show={showModalMedia}
            actionShowModal={actionShowModalMedia}
            title={`Додати зображення користувача`}
            btn_name='Додати зображення'
            content={
              <MediaFrom user_id={data.id} actionShowModal={actionShowModalMedia} />
            }
          />
        </>
      }
      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">username</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">plainPassword</CFormLabel>
          <CFormInput type="password" id="exampleFormControlInput2" value={plainPassword} onChange={(e) => setPlainPassword(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Роль</CFormLabel>
          <Select
            options={roleList}
            value={roles}
            onChange={(value) => {
              handleChangeRole(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Ім'я</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Прізвище</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput5">Телефон</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">Email</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput7">Telegram</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput7" value={telegram} onChange={(e) => setTelegram(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput8">Viber</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput8" value={viber} onChange={(e) => setViber(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput9">Графік роботи</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput9" value={workSchedule} onChange={(e) => setWorkSchedule(e.target.value)} />
        </div>

        {/* <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>

      <hr/>
      <CForm
          onSubmit={handleSubmitFile}
          noValidate
          validated={validated}
          className='mt-5'
        >
          <h4>Додати документи</h4>
          <CRow className="mb-3">
            <CCol xs={4}>
              <input className="form-control form-control-lg" id="formFileLg" type="file" 
              onChange={handleChange} 
              />
            </CCol>
            <CCol xs={2}>
              <input className="form-control form-control-lg" id="formFileLg" type="text" placeholder='Назва' 
              onChange={(e) => setFileName(e.target.value)} 
              />
            </CCol>
            <CCol xs={2}>
              {/* <Select 
                options={options}
                value={options.value}
                id='type_select'
                onChange={(e) => setFileType(e.target.value)} 
              >
        
              </Select> */}
              <input className="form-control form-control-lg" id="formFileLg" type="text" placeholder='Тип' 
              onChange={(e) => setFileType(e.target.value)} ></input>
            </CCol>
            <CCol xs={2}>
              <input className="form-control form-control-lg" id="formFileLg" type="text" placeholder='Опис' 
              onChange={(e) => setFileDescr(e.target.value)} 
              />
            </CCol>
            <CCol xs={2}>
              <CButton 
              type='submit'
                color="warning"
                onClick={handleSubmitFile}>Зберегти</CButton>
            </CCol>
          </CRow>
        </CForm>
      <div style={{ height: 300, width: '100%' }} className='mt-5'>
        <DataGrid rows={tableData} columns={columns}></DataGrid>
      </div>
    </>
  );
};

export default UsersForm;