import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { Link } from 'react-router-dom';

const CouponsCreateForm = (props) => {
    // console.log("CouponsCreateForm ", props);
    let data = props.data;

    const urlApi = '/coupons';
    const urlRouter = '/coupons';

    const navigate = useNavigate();
    const [name, setName] = useState((data != undefined) ? data.name : '');
    const [code, setCode] = useState((data != undefined) ? data.code : '');
    const [sum, setSum] = useState((data != undefined) ? data.sum : '');
    const [percent, setPercent] = useState((data != undefined) ? data.percent : '');
    const editorRef = useRef(null);
    // Відобразити "Суму" або "Відсоток"
    const [choice, setChoice] = useState("-- Виберіть варіант --");
    // Значення, яке заповниться в "Суму" або "Відсоток"
    const [choiceValue, setChoiceValue] = useState("");
    // Заблокувати input та button для поля code, якщо це put
    const [disabled, setDisabled] = useState(false);
    // Текст для popup
    const popupTextPostSuccess = "Купон створено";
    const popupTextPutSuccess = "Купон оновлено";
    const popupTextWarning_1 = "Введений \"Код\" вже існує";
    const popupTextWarning_2 = "Error";

    // При зміні "Сума" або "Відсоток" обнулити значення, яке заповниться в "Суму" або "Відсоток"
    useEffect(() => {
        // Обнулити
        setChoiceValue("");
    }, [choice]);

    // Функція для заповнення одного з полів "Сума"/"Відсоток", а інше поле обнулить
    useEffect(() => {
        if (choice === "sum") {
            setSum(choiceValue);
            setPercent(0);
        };
        if (choice === "percent") {
            setPercent(choiceValue);
            setSum(0);
        };
    }, [choiceValue]);

    // При редагуванні "Fees" заповнити одне з полів "Сума"/"Відсоток"
    useEffect(() => {
        if (data != undefined) {
            // if (data.percent != undefined && data.percent === 0) {
            if (data.percent === 0) {
                setChoice("sum");
                // Необхідна затримка в часі, щоб інший useEffect не перетер choiceValue (він спрацьовує при зміні choice "Сума"/"Відсоток")
                setTimeout(() => {
                    setChoiceValue(data.sum);
                }, 500);
            };
            if (data.sum === 0) {
                setChoice("percent");
                // Необхідна затримка в часі, щоб інший useEffect не перетер choiceValue (він спрацьовує при зміні choice "Сума"/"Відсоток")
                setTimeout(() => {
                    setChoiceValue(data.percent);
                }, 500);
            };
        };
    }, [data]);

    // Заблокувати input та button для поля code, якщо це put
    useEffect(() => {
        if (data != undefined) {
            setDisabled(true);
        };
    }, [data]);

    const handleSubmit = event => {
        event.preventDefault();
        if (editorRef.current) {
            // console.log(editorRef.current.getContent());

            // Дані, які відправляться на сервер 
            // Для POST
            const article_1 = {
                name: name,
                code: code.toUpperCase(),
                sum: parseFloat(sum),
                percent: parseFloat(percent),
                description: editorRef.current.getContent(),
                active: 'on',
            };

            // Для PUT
            const article_2 = {
                name: name,
                sum: parseFloat(sum),
                percent: parseFloat(percent),
                description: editorRef.current.getContent(),
                active: 'on',
            };

            // Створити купон
            if (data === undefined) {
                // Запит на /coupons по полю code (чи такий код існує)
                AxiosInstance.get(`${urlApi}?code=${code}`).then((response) => {
                    if (response.status === 200) {
                        // Якщо код відсутній
                        if (response.data['hydra:totalItems'] === 0) {
                            // Створити купон
                            AxiosInstance.post(`${urlApi}`, article_1).then((response) => {
                                // console.log("post create response", response);
                                if (response.status === 201) {
                                    // Popup про успішні зміни
                                    props.toastifySuccess(popupTextPostSuccess);
                                    return navigate("/coupons/update/" + response.data.id);
                                }
                            });
                        } else {
                            // Введіть інше значення в поле "Код"
                            // Popup попередження
                            props.toastifyWarning(popupTextWarning_1);
                        }
                    } else {
                        // alert("Error");
                        // Popup попередження
                        props.toastifyWarning(popupTextWarning_2);
                    };
                });
            };

            // Оновити купон
            if (data != undefined) {
                AxiosInstance.put(`${urlApi}/${data.id}`, article_2).then((response) => {
                    if (response.status === 200) {
                        // Popup про успішні зміни
                        props.toastifySuccess(popupTextPutSuccess);
                        return navigate("/coupons/update/" + response.data.id);
                    } else {
                        // alert("Error");
                        // Popup попередження
                        props.toastifyWarning(popupTextWarning_2);
                    };
                });
            };
        };
    };

    // Рандомний код
    const funcRandomCode = () => {
        // Довжина рандомного коду
        const lengthCode = 8;
        // Результат рандомного коду
        let result = '';
        // Можилві символи в рандомному коді
        // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < lengthCode) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        setCode(result);
    };

    return (
        <>
            <CForm onSubmit={handleSubmit} className="mb-3">
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>

                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput2">Код</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput2" value={code} onChange={(e) => setCode(e.target.value)} minLength={6} required disabled={disabled} />
                    <CButton className="mt-2" onClick={funcRandomCode} color="secondary" disabled={disabled}>Згенерувати код</CButton>
                </div>

                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput3">Сума/Відсоток</CFormLabel>
                    <select className="form-select" value={choice} onChange={(e) => setChoice(e.target.value)}>
                        <option disabled>-- Виберіть варіант --</option>
                        <option value="sum">Сума</option>
                        <option value="percent">Відсоток</option>
                    </select>
                    {
                        // Відображати Input тільки при виборі "Сума"/"Відсоток"
                        (choice !== "-- Виберіть варіант --") &&
                        <CFormInput className="mt-3" type="text" id="exampleFormControlInput2" value={choiceValue} onChange={(e) => setChoiceValue(e.target.value)} />
                    }
                </div>

                {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Сума</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput2" value={sum} onChange={(e) => setSum(e.target.value)} />
          </div> */}
                {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Відсоток</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput3" value={percent} onChange={(e) => setPercent(e.target.value)} />
          </div> */}

                <div className="mb-3">
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={(data != undefined) ? data.content : ''}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </div>

                <CButton type="submit" color="success">Зберегти</CButton>
                <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
            </CForm>
        </>
    );
};

export default CouponsCreateForm;