import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
// import NewsCategories from 'src/components/news/NewsCategories';

const urlApi = '/news';
const urlRouter = '/news';

export async function LoaderNewsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.newsId}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const newsForm = () => {
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined) ? data.newsShot : '');
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [MyData, setMyData] = useState(data);
  const [videoImg, setVideoImg] = useState((data != undefined) ? data.videoImg : '');
  const [slider, setSlider] = useState((data != undefined) ? data.slider : '');
  const [dateStart, setDateStart] = useState((data != undefined) ? data.dateStart : '');
  const [address, setAddress] = useState((data != undefined) ? data.address : '');
  const [shortDescription, setShortDescription] = useState((data != undefined) ? data.shortDescription : '');
  const [contentImg, setContentImg] = useState((data != undefined) ? data.contentImg : '');

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess_1 = "Оновлено";
  const popupTextSuccess_2 = "Створено";
  const popupTextWarning_1 = "Не оновлено";
  const popupTextWarning_2 = "Не створено";

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        newsShot: newsShot,
        newsFull: editorRef.current.getContent(),
        date: date,
        videoImg: videoImg,
        slider: slider,
        dateStart: dateStart,
        address: address,
        shortDescription: shortDescription,
        contentImg: contentImg,
        // active: ""
      };

      if (data != undefined) {
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          // console.log(response);
          if (response.status === 200) {
            popup.toastifySuccess(popupTextSuccess_1);
          } else {
            popup.toastifyWarning(popupTextWarning_1);
          }
        });
      } else {
        AxiosInstance.post(urlApi, article).then((response) => {
          // console.log(response);
          if (response.status === 201) {
            popup.toastifySuccess(popupTextSuccess_2);
            navigate(`${urlRouter}/update/${response.data.id}`);
          } else {
            popup.toastifyWarning(popupTextWarning_2);
          }
        });
      }
    }
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Заголовок</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={newsShot} onChange={(e) => setNewsShot(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Посилання на зображення</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={videoImg} onChange={(e) => setVideoImg(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Фонове фото</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={address} onChange={(e) => setAddress(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Дата</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Опис головної новини</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Слайдер</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={slider} onChange={(e) => setSlider(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Зображення контенту</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={contentImg} onChange={(e) => setContentImg(e.target.value)} />
        </div>
        <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.newsFull : ''}
            init={{
              height: 250,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help | link image | code',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              style_formats: [
                { title: 'Headers', items: [
                  { title: 'Header 1', format: 'h1' },
                  { title: 'Header 2', format: 'h2' },
                  { title: 'Header 3', format: 'h3' },
                  { title: 'Header 4', format: 'h4' },
                  { title: 'Header 5', format: 'h5' },
                  { title: 'Header 6', format: 'h6' },
                ] },
                { title: 'Font Sizes', items: [
                  { title: 'Small', format: 'small' },
                  { title: 'Normal', format: 'normal' },
                  { title: 'Large', format: 'large' },
                  { title: 'Huge', format: 'huge' },
                ] },
              ],
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>

      {/* Категорії */}
      {/* {(MyData != undefined) ? <NewsCategories news_id={MyData.id} toastifySuccess={popup.toastifySuccess} /> : ''} */}
    </>
  );
};

export default newsForm;