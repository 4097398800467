import React, { useState, useEffect } from 'react';
import AxiosInstance from '../../Axios';
import ViewOrdersMain from './ViewOrdersMain';
import ViewOrdersModal from './ViewOrdersModal';
import { confirmAlert } from 'react-confirm-alert';

// Табиця з товарами, які додані до замовлення
const ViewProductOrders = (props) => {
    // console.log("props View", props);

    const urlApi = '/order_products';
    // Список доданих товарів до замовлення
    const [products, setProducts] = useState({});
    // Сума замовлення, яка прийшла з API
    const [oldSum, setOldSum] = useState(0);
    // Текст для popup
    const popupTextSuccess = "Товар видалено";

    // Запит за order_products
    useEffect(() => {
        if (props.getOP === true) {
            getOrderProducts();
        };
    }, [props.getOP]);

    // Функція для запиту order_products
    const getOrderProducts = () => {
        AxiosInstance.get(`${urlApi}?order=${props.data.id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response data", response);
            // Наповнити зписок з доданими товарами для виводу в таблицю доданих товарів до замовлення
            setProducts(response.data);
            // Наповнити зписок  з доданими товарами на рівень вверх для вибору відображення іконки додано або додати в в список замовлення
            if (props.setProducts != undefined) {
                props.setProducts(response.data);
            };
            // Змінити значення getOP ( get order_producs) на false для можливого подальшого запиту
            props.setGetOP(false);
        });
    };

    // Функція для видалення товару з списку
    const deleteProduct = (id) => {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        AxiosInstance.delete(`${urlApi}/${id}`).then((response) => {
                            if (response.status === 204) {
                                // Popup про успішні зміни
                                props.toastifySuccess(popupTextSuccess);
                                // Оновити список доданих товарів до замовлення
                                props.setGetOP(true)
                            };
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ],
            overlayClassName: "myAllert" // App.css .myAllert "z-index: 9999999999"; щоб повідомлення було поверх модального вікна
        });
    };

    // Формування суми замовлення
    useEffect(() => {
        let sum = 0;
        if (products.length > 0) {
            products.map((product) => {
                let sumOrdered = (product.sumOrdered != undefined) ? product.sumOrdered : 0;
                sum += sumOrdered;
            });
        }
        setOldSum(sum);
        // console.log("sum", sum);
    }, [products]);

    // Передати суму замовлення на верх
    useEffect(() => {
        props.setSumOrder(oldSum);
    }, [oldSum]);

    return (
        <>
            {/* Для сторінки orders/update/[id] */}
            {
                (props.type === "main") &&
                <ViewOrdersMain products={products} deleteProduct={deleteProduct} />
            }

            {/* Для модального вікна додавання товару сторінки orders/update/[id] */}
            {
                (props.type === "modal") &&
                <ViewOrdersModal products={products} deleteProduct={deleteProduct} setOpenUpdateProduct={props.setOpenUpdateProduct} setProductForUpdate={props.setProductForUpdate} />
            }
        </>
    );
};

export default ViewProductOrders;