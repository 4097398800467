import { useState, useEffect } from "react";
import ChooseProduct from "./ChooseProduct/ChooseProduct";
// import ChooseProduct from "./ChooseProduct(loadOptions)";
import ProductInfo from "./ProductInfo";
import AxiosInstance from 'src/components/Axios';
import ViewProductOrders from "./ViewProductOrders/ViewProductOrders";
import UpdateProductItem from "./UpdateProductItem";

// Модальне вікно для додавання товару до замовлення 
const AddProductToOrder = (props) => {
    // console.log("props", props);
    const urlApi = '/order_products';
    // Вибраний товар з ChooseProduct
    const [selectedProductId, setSelectedProductId] = useState(false);
    // Доданий товар
    const [addedProduct, setAddedProduct] = useState(false);
    // Дані про товар, які будуть додані до замовлення
    const [productData, setProductData] = useState(false);
    // Показати таблицю з доданими товарами до замовлення або продукт
    const [showTable, setShowTable] = useState(true);
    // Товари, які є в списку замовлення
    const [products, setProducts] = useState(false);
    // Відкрити/закрити вікно для оновлення даних товару (кількість)
    const [openUpdateProduct, setOpenUpdateProduct] = useState(false);
    // Продукт для оновлення кількості
    const [productForUpdate, setProductForUpdate] = useState(false);
    // Текст для popup
    const popupTextSuccess = "Товар додано";

    // useEffect(() => {
    //     console.log("props.productDataQuick", props.productDataQuick);
    // }, [props.productDataQuick]);

    // useEffect(() => {
    //     if (productForUpdate != false) {
    //         console.log("productForUpdate", productForUpdate);
    //     }
    // }, [productForUpdate]);

    // useEffect(() => {
    //     console.log("openUpdateProduct", openUpdateProduct);
    // }, [openUpdateProduct]);

    // Коли натиснути оновлення товару, змінити showTable на false
    useEffect(() => {
        if (openUpdateProduct === true) {
            setShowTable(false);
        }
    }, [openUpdateProduct]);

    // Функція для додавання товару
    const addToOrder = (product) => {
        setAddedProduct(product);
    };

    // Передати товар для формування даних про товар для додавання до замовлення
    useEffect(() => {
        if (addedProduct != false) {
            // console.log("addedProduct", addedProduct);
            ordersProducts(addedProduct);
        }
    }, [addedProduct]);

    // Показати детальну інформацію про товар (ProductInfo) 
    useEffect(() => {
        if (selectedProductId != false) {
            // console.log("selectedProductId", selectedProductId);
            // Показати модуль з вибраним товаром
            setShowTable(false);
        };
    }, [selectedProductId]);

    // Формування даних про товар для додавання до замовлення
    const ordersProducts = (addedProduct) => {
        // Заокруглені значення до 2-х знаків після коми
        const priceForOne = addedProduct.price != undefined ? addedProduct.price.toFixed(2) : 0;
        const sumPrice = (priceForOne * addedProduct.count).toFixed(2);

        setProductData({
            "order": `api/orders/${props.data.id}`,
            "name": addedProduct.name,
            "product": "/api/products/" + addedProduct.id,
            "count": addedProduct.count,
            "measurementUnit": (addedProduct.measurement_unit != undefined) ? `/api/measurment_units/${addedProduct.measurement_unit.id}` : null,
            "discount": 0,
            "curency": "uah",
            "priceForOne": parseFloat(priceForOne),
            "sumOrdered": parseFloat(sumPrice)
        });
    };

    // Запит на API для додавання товару до замовлення
    useEffect(() => {
        if (productData != false) {
            // console.log("productData", productData);

            // Запит на /order_products по orders та product.id (чи такий товар вже додано до замовлення)
            AxiosInstance.get(`${urlApi}?order=${props.data.id}&product.id=${addedProduct.id}`).then((response) => {
                if (response.status === 200) {
                    // console.log("response get product.id", response);
                    if (response.data['hydra:totalItems'] === 0) {
                        // Додати товар до списку замовлення
                        AxiosInstance.post(`${urlApi}`, productData).then((response) => {
                            if (response.status === 201) {
                                // console.log("response", response);
                                // alert("Товар додано до замовлення");
                                // Popup про успішні зміни
                                props.toastifySuccess(popupTextSuccess);
                                // Оновити список order_products
                                props.setGetOP(true);
                                // Після додавання продукту, якщо відкрите модальне вікно, закрити інформацію про продукт і відобразити список з доданими товарами
                                setShowTable(true);
                            };
                        });
                    } else {
                        alert("Даний товар вже додано");
                    };
                };
            });
        };
    }, [productData]);

    // console.log("ordersProducts", ordersProducts);


    return (
        <div style={{ position: "relative" }}>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        {
                            (showTable) ?
                                <>
                                    {/* Список з доданими товарами до замовлення */}
                                    <ViewProductOrders data={props.data} getOP={props.getOP} setGetOP={props.setGetOP} type="modal" setSumOrder={props.setSumOrder} setProducts={setProducts}
                                        setOpenUpdateProduct={setOpenUpdateProduct} setProductForUpdate={setProductForUpdate} toastifySuccess={props.toastifySuccess}
                                    />
                                </>
                                :
                                <>
                                    {
                                        (!openUpdateProduct) ?
                                            // "Інформація про товар"
                                            <ProductInfo
                                                selectedProductId={selectedProductId} addToOrder={addToOrder} setShowTable={setShowTable}
                                                setGetOP={props.setGetOP} products={products} />
                                            :
                                            // "Оновити продукт"
                                            <UpdateProductItem
                                                product={productForUpdate} setShowTable={setShowTable} setGetOP={props.setGetOP}
                                                setOpenUpdateProduct={setOpenUpdateProduct} toastifySuccess={props.toastifySuccess} />
                                    }
                                </>
                        }
                </div>
                {/* Вибрати товар зі списку */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <ChooseProduct setSelectedProductId={setSelectedProductId} addToOrder={addToOrder} products={products} />
                </div>
            </div>
            <div
                onClick={() => props.setShowModal(!props.showModal)} title="Закрити модальне вікно"
                style={{ cursor: "pointer", backgroundColor: "red", position: "absolute", top: "-40px", right: "-10px", height: "25px", width: "25px", border: "1px solid #000", textAlign: "center" }}>
                Х
            </div>
        </div>
    )
};

export default AddProductToOrder;