import { useState, useEffect } from "react";
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { cilBasket, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Інформація про товар
const ProductInfo = (props) => {
    // console.log("ProductInfo props", props);

    const urlApi = '/products';
    // Дані про продукт
    const [product, setProduct] = useState(false);
    // Зображення для продукту
    const media = (product.media != undefined && product.media.length > 0) ? product.media : "";
    // Вхідна ціна одиниці товару
    const productIncomePrice = (props.product != undefined && props.product.priceIncome != undefined) ? props.product.priceIncome : '0';
    // Чи даний товар є в списку замовлень
    const [added, setAdded] = useState(false);
    // Кількість товару
    const [counter, setCounter] = useState(1);
    const [count, setCount] = useState(1);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    }
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };
    useState(() => {
        setCount(counter);
    }, [counter]);

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let count = event.target.value === "" ? 1 : parseFloat(event.target.value);
        setCounter(count);
    };

    // Виклик ф-ції для запиту за інформацією про продукт
    useEffect(() => {
        if (props.selectedProductId != false) {
            // console.log("props.selectedProductId", props.selectedProductId);
            getProduct();
        }
    }, [props.selectedProductId]);

    // Функція для запиту за інформацією про продукт
    const getProduct = () => {
        AxiosInstance.get(`${urlApi}/${props.selectedProductId}`).then((response) => {
            // console.log("get /api/products/ response", response);
            setProduct(response.data);
        });
    };

    // Додавання товару до замовленя
    const handleAddToOrder = (product) => {
        // Додати до продукту кількість штук count: counter
        const orderCount = { ...product, count: counter }
        // Додавати товар в кошик замовлень
        props.addToOrder(orderCount);
    };

    // Функція для відкриття модального вікна з списком доданих товарів
    const changeModal = () => {
        // Показати таблицю з доданими до замовлення товарами
        props.setShowTable(true);
        // Для запиту за списком з доданими товарами до замовлення
        props.setGetOP(true);
    };

    // Відслідковувати зміну додавання/видалення з кошика замовлень
    useEffect(() => {
        let isInArray = false;
        props.products.some(el => {
            // Порівняти id - чи є в кошику замовлень
            if (product != false && el.product.id === product.id) {
                isInArray = true
            };
        });
        setAdded(isInArray);
    }, [product]);

    return (
        <div style={{ position: "relative", border: "1px solid #000", padding: "10px", marginTop: "13px" }}>
            <span onClick={changeModal} style={{ cursor: "pointer", backgroundColor: "red", position: "absolute", top: "0", right: "0", height: "25px", width: "25px", border: "1px solid #000", textAlign: "center" }}>X</span>
            <h5>{product.name}</h5>

            <div className="row">
                <div className="col-xxl-8 col-xl-6">
                    <Carousel
                        className="me-3" style={{ height: '200px', width: 'auto' }}
                        showArrows={false} showThumbs={true} showStatus={false} thumbWidth={60}
                    >
                        {
                            // Відобразити всі зображення до продукту
                            (media != '') &&
                            media.map(el => {
                                return (
                                    <div key={el.id}>
                                        <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="col-xxl-4 col-xl-6">
                    <div className="orders-productInfo__text">Артикул: {product.article}</div>
                    <div className="orders-productInfo__text">Вхідна ціна: {productIncomePrice} грн.</div>
                    <div className="orders-productInfo__text">Ціна: {product.price} грн.</div>
                    <div className="orders-productInfo__text">Код1с: {product.code1c}</div>
                    <div>
                        {
                            (!added) ?
                                // Якщо товар не доданий до замовлення
                                <div className="orders-productInfo__text">
                                    <button className='me-1' onClick={productDecrement}>-</button>
                                    <input className='me-1 text-center' type="text" pattern="[0-9]*" value={counter} onChange={inputChange} style={{ width: "40px" }} />
                                    <button className='me-2' onClick={productIncrement}>+</button>
                                    <CIcon icon={cilBasket} customClassName="nav-icon" height={18} onClick={() => handleAddToOrder(product)} style={{ cursor: "pointer" }} />
                                </div>
                                :
                                // Якщо товар вже доданий до замовлення
                                <div className="orders-productInfo__text">
                                    <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={18} style={{ color: "green" }} />
                                    <span className="ms-2">Даний товар вже доданий</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProductInfo;