import React, { Fragment } from 'react';
import { CForm, CFormInput, CFormTextarea, CButton, CInputGroup, CFormLabel } from '@coreui/react';
import { cilPen, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import UpdateFormField from './UpdateFormField';
import { useState } from 'react';
import { StyledEngineProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// Компонент для відображення полів
const RenderInputFields = (props) => {
    // console.log('props', props);

    // Стилі для модального вікна
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // Поле для редагування
    const [formField, setFormField] = useState(false);
    // Для молального вікна оновлення поля форми
    const [open, setOpen] = useState(false);

    // Функція для відкриття модального вікна
    const handleOpen = (el) => {
        setOpen(true);
        // Визначити поле для редагування
        setFormField(el);
    };

    // Функція для закриття модального вікна
    const handleClose = () => setOpen(false);

    return (
        <div>
            {
                props.formFields.map((el) => {
                    // console.log('el', el);
                    return (
                        <Fragment key={el.id}>
                            {
                                (el.type === "textarea") ?
                                    <div className="row">
                                        <div className='col-8'>
                                            <CFormTextarea
                                                className='mb-3'
                                                id={`${el.name}_${el.id}`}
                                                // label="Опис форми"
                                                label={el.label}
                                                rows={3}
                                                placeholder={el.defaultValue}
                                            // text="Must be 8-20 words long."
                                            ></CFormTextarea>
                                        </div>

                                        <div className='col d-flex align-items-center'>
                                            <CInputGroup className="mb-3">
                                                <CButton color="dark" variant="outline">
                                                    <CIcon icon={cilPen} customClassName="nav-icon" height={20} onClick={() => handleOpen(el)} />
                                                </CButton>
                                                <CButton type="button" color="secondary" variant="outline" onClick={() => props.deleteField(el.id)}>
                                                    <CIcon icon={cilTrash} customClassName="nav-icon" height={20} style={{ color: "red" }} />
                                                </CButton>
                                            </CInputGroup>
                                        </div>
                                    </div>

                                    :

                                    <div>
                                        <CFormLabel htmlFor="staticEmail">{el.label}</CFormLabel>
                                        <CInputGroup className="mb-3">
                                            <CFormInput
                                                type={el.type}
                                                id={`${el.name}_${el.id}`}
                                                // label={el.label}
                                                placeholder={el.defaultValue}
                                            // text="Must be 8-20 characters long."
                                            // aria-describedby="exampleFormControlInputHelpInline"
                                            />
                                            <CButton color="dark" variant="outline">
                                                <CIcon icon={cilPen} customClassName="nav-icon" height={20} onClick={() => handleOpen(el)} />
                                            </CButton>
                                            <CButton type="button" color="secondary" variant="outline" onClick={() => props.deleteField(el.id)}>
                                                <CIcon icon={cilTrash} customClassName="nav-icon" height={20} style={{ color: "red" }} />
                                            </CButton>
                                        </CInputGroup>
                                    </div>
                            }

                            {/* Модальне вікно для оновлення поля форми */}
                            <StyledEngineProvider injectFirst>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Оновити поле
                                        </Typography>
                                        <UpdateFormField formField={formField} handleClose={handleClose} getFormsData={props.getFormsData} />
                                    </Box>
                                </Modal>
                            </StyledEngineProvider>

                        </Fragment>
                    )
                })
            }
        </div >
    );
};

export default RenderInputFields;