import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AxiosInstance from '../Axios';
// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/pre_orders';

const ViewProductOrders = (props) => {
    const data = props.data;
    const [products, setProduct] = useState({});

    useEffect(() => {
        AxiosInstance.get(`/pre_order_products?preOrder=${props.data.id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            return setProduct(response.data);
        });
    }, [props]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>#</strong></TableCell>
                            <TableCell align="center"><strong>Зображення</strong></TableCell>
                            <TableCell align="center"><strong>Назва</strong></TableCell>
                            <TableCell align="center"><strong>Ціна</strong></TableCell>
                            <TableCell align="center"><strong>Кількість</strong></TableCell>
                            <TableCell align="center"><strong>Одиниці Вим.</strong></TableCell>
                            <TableCell align="center"><strong>Сума</strong></TableCell>
                            <TableCell align="center"><strong>Знижка</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (products.length > 0) ?
                                products.map((value, key) => {
                                    let i = key++ + 1;
                                    let imgUrl = (value.product.media != undefined && value.product.media.length > 0) ? `${process.env.REACT_APP_SERVER_URL}${value.product.media[0].contentUrl}` : 'product.jpg';
                                    return (
                                        <TableRow>
                                            <TableCell align="center"><strong>{i}</strong></TableCell>
                                            <TableCell align="center">
                                                <Link to={`/products/update/${value.product.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <strong><img src={imgUrl} alt="blog-image" style={{ width: "50px" }} /></strong>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Link to={`/products/update/${value.product.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <strong>{value.product.name}</strong>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center"><strong>{value.priceForOne}</strong></TableCell>
                                            <TableCell align="center"><strong>{value.count}</strong></TableCell>
                                            <TableCell align="center"><strong>{value.measurementUnit.short_name}</strong></TableCell>
                                            <TableCell align="center"><strong>{value.sumOrdered}</strong></TableCell>
                                            <TableCell align="center"><strong>{value.discount}</strong></TableCell>
                                        </TableRow>
                                    )
                                })
                                : ''
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ViewProductOrders;