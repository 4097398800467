import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";

const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };

export async function LoaderPagesUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/pages/' + params.newsId, header).then((response) => {
      return response;
    });
    return res;
  }
  return  false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const pagesForm = () => {
  const {data}  = useLoaderData();
  const [name, setName] = useState((data != undefined)? data.name : '');
  const [synonym, setSynonym] = useState((data != undefined)? data.synonym : '');
  const [mainImg, setmainImg] = useState((data != undefined)? data.images : '');
  const editorRef = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const article = {
        name: name,
        synonym: synonym,
        content: editorRef.current.getContent(),
        active: 'on',
        images: mainImg,
      };
      if (data != undefined){
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/pages/' + data.id , article, header);
      }else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/pages', article, header);
      }
    }
  }


    return (
      <>
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput2" value={synonym} onChange={(e) => setSynonym(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Головне зображення</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput3" value={mainImg} onChange={(e) => setmainImg(e.target.value)} />
            <Link to="/media" target='_blanck'>перейти до медіа</Link>
          </div>
          <div className="mb-3">
            <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={(data != undefined)? data.content : ''}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
          </div>
          <CButton type="submit" color="success">Зберегти</CButton>
        </CForm>
      </>
    );
};

export default pagesForm;
