import React, { useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';

const urlApi = '/site_products';
const urlRouter = '/site-products';

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderSiteProducts({ params }) {
  let res = await  AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
    return response;
  });
  return res;
}

const SiteProducts = () => {
  const {data}  = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  if(!rows){
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  }
  const getRowsTable = (p=1) => {
    AxiosInstance.get(`${urlApi}?page=${p}`).then((response) => {
        setRows(response.data['hydra:member']);
      });
  };  
  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  }
  
  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'newsShot', headerName: 'Опис', width: 400 },
    {
      field: 'created_at',
      headerName: 'Дата',
      type: 'date',
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
            Deleted(params.row)
          }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];


  return (
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Товари</strong> <small>відповідники товарів для сайту</small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height="15" />Створити</CButton></Link>
              </p>
              <div style={{ height: 400, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
};

export default SiteProducts;
