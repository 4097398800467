import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';

const urlApi = '/measurment_units';
const urlRouter = '/measurment-unit';
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } }

export async function LoaderMeasurmentUnitUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const MeasurmentUnitForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [short_name, setShortName] = useState((data != undefined) ? data.short_name : '');
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [code1c, setCode1c] = useState((data != undefined) ? data.code1c : '');
  const editorRef = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const data_response = {
        short_name: short_name,
        name: name,
        code1c: code1c,
        active: 'on',
      };
      if (data != undefined) {
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response, header).then((response) => {
          if (response.status === 200)
            return navigate(urlRouter);
        });
      } else {
        const response = AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
          if (response.status === 201)
            return navigate(urlRouter);
        });
      }
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Коротка назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={short_name} onChange={(e) => setShortName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">code1c</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={code1c} onChange={(e) => setCode1c(e.target.value)} />
        </div>

        <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>
    </>
  );
};

export default MeasurmentUnitForm;