import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { CCard, CCardBody, CCardHeader, CButton } from '@coreui/react';
import FormOrders from 'src/components/orders/FormOrders';
import ViewOrders from 'src/components/orders/ViewOrders';
import FormProductOrders from 'src/components/orders/FormProductOrders';
import ViewProductOrders from 'src/components/orders/ViewProductOrders/ViewProductOrders';
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import ViewMediaOrders from 'src/components/orders/ViewMediaOrders';
import AddProductToOrder from 'src/components/orders/AddProductToOrder';
import { Spinner } from 'react-bootstrap';
import { GrStatusGood } from 'react-icons/gr';
import { confirmAlert } from 'react-confirm-alert';
import QuickProductAddition from 'src/components/orders/QuickProductAddition';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/orders';
const urlRouter = '/orders';

export async function LoaderOrdersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};


const OrdersForm = () => {
  const { data } = useLoaderData();

  const [MyData, setMyData] = useState(data);
  const [show, setShow] = useState((data == undefined) ? false : true);
  const [showModal, setShowModal] = useState(true);
  const [showModalMedia, setShowModalMedia] = useState(true);
  // Для запиту за order_products
  const [getOP, setGetOP] = useState(true);
  // Сума замовлення
  const [sumOrder, setSumOrder] = useState(0);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Товар збережено";


  useEffect(() => {
    setMyData(data);
  }, [data]);

  // useEffect(() => {
  //   console.log("sumOrder", sumOrder);
  // }, [sumOrder]);

  // const [name, setName] = useState((data != undefined) ? data.name : '');
  // const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  // const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');


  const actionShowModal = (rez) => {
    setShowModal(rez);
    if (rez != true)
      console.log("Modal hide");
  };

  const actionShowModalMedia = (rez) => {
    setShowModalMedia(rez);
    console.log(11111);
    if (!rez)
      getMedia();
  };

  const syncedClick = () => {
    confirmAlert({
      title: 'Ви впевнені що хочите передати дані в 1с ?',
      message: 'Після передавання даних в 1с, Редагувати замовлення на сайті не можна.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            AxiosInstance.put(`${urlApi}/${data.id}`, { synced: false })
            let res = AxiosInstance.get(`${urlApi}/${data.id}`).then((response) => {
              setMyData(response.data)
              return response;
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CCard className="mb-4">
        <CCardHeader>
          <div className='row'>
            <div className='col'>
              <strong>Дані замовлення</strong> <small>{(MyData != undefined) ? `#${MyData.id}` : ''}</small>
            </div>
            <div className='col d-flex justify-content-end'>
              {(MyData != undefined) &&
                <>
                  синхрон в 1с -
                  {(MyData.synced == null) && <CButton className='ml-3' onClick={() => { syncedClick() }}>Синхронізувати</CButton>}
                  {(MyData.synced == false) && <Spinner animation="grow" variant="primary" />}
                  {(MyData.synced == true) && <GrStatusGood />}
                </>
              }
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          {(show) ? <ViewOrders data={MyData} sumOrder={sumOrder} /> : <FormOrders data={MyData} setShow={setShow} toastifySuccess={popup.toastifySuccess} />}
        </CCardBody>
      </CCard>
      {(data != undefined) ?
        <>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Товари</strong>
              <div className='d-flex'>
                <div>
                  {/* Додати/Оновити товар */}
                  {(show && MyData != undefined) ?
                    <BasicModal
                      show={showModal}
                      actionShowModal={actionShowModal}
                      title={(MyData != undefined) ? `Додати товар до замовлення №${MyData.id}` : ''}
                      content={
                        <AddProductToOrder
                          data={MyData} getOP={getOP} setGetOP={setGetOP}
                          showModal={showModal} setShowModal={setShowModal} setSumOrder={setSumOrder}
                          toastifySuccess={popup.toastifySuccess} />
                      }
                      type="orders"
                      setGetOP={setGetOP}
                      btn_name="Додати/Оновити"
                    />
                    : <></>}
                </div>
                <div className='ms-2'>
                  {/* Швидке додавання товару */}
                  {(show && MyData != undefined) ?
                    <BasicModal
                      show={showModal}
                      actionShowModal={actionShowModal}
                      title={(MyData != undefined) ? `Додати товар до замовлення №${MyData.id}` : ''}
                      content={
                        // <FormProductOrders data={MyData} actionShowModal={actionShowModal} />
                        <QuickProductAddition data={MyData} setGetOP={setGetOP} setShowModal={setShowModal} toastifySuccess={popup.toastifySuccess} />
                      }
                      setGetOP={setGetOP}
                      btn_name="Швидке додавання"
                    />
                    : <></>}

                </div>
              </div>

            </CCardHeader>
            <CCardBody>
              {(MyData != undefined) ?
                // (show) ? <ViewProductOrders data={MyData} /> : <FormProductOrders data={MyData} />
                (show) ?
                  <ViewProductOrders data={MyData} getOP={getOP} setGetOP={setGetOP} type="main" setSumOrder={setSumOrder} toastifySuccess={popup.toastifySuccess} />
                  : <AddProductToOrder data={MyData} />
                : <></>
              }
            </CCardBody>
          </CCard>
          {(MyData != undefined) ?
            <CCard className="mb-4">
              <CCardHeader>
                <strong>СЕРТИФІКАТИ ТА ДЕКЛАРАЦІЇ</strong>
                <BasicModal
                  show={showModalMedia}
                  actionShowModal={actionShowModalMedia}
                  title={`Додати сертифікат або деларацію до замовлення №${MyData.id}`}
                  content={
                    <MediaFrom order_id={MyData.id} actionShowModal={actionShowModalMedia} />
                  }
                />
              </CCardHeader>
              <CCardBody>
                <ViewMediaOrders data={MyData} showModalMedia={showModalMedia} />
              </CCardBody>
            </CCard>
            : <></>}
        </>
        : ''
      }
    </>
  );
};

export default OrdersForm;