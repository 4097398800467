import React, { useEffect, useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CCol,
  CRow,
  CButton
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const urlApi = '/agreements';
const urlRouter = '/agreements';

const role = localStorage.getItem('user_role');
const user_data = JSON.parse( localStorage.getItem('user_data') );
const user_id = (user_data != null && user_data != undefined && user_data.id != undefined) ? user_data.id: false;
const defaultUserFilter = (role == 'ROLE_MANAGER' && user_id)? `&account.manager=${user_id}`: '';



export async function LoaderAgreements({ params }) {
  // console.log("params LoaderAgreements", params);
  let res = await AxiosInstance.get(`${urlApi}?page=${page}` + defaultUserFilter).then((response) => {
    // console.log("response LoaderAgreements", response);
    return response;
  });
  return res;
}

const Agreements = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [accountNameFilterSearch, setAccountNameFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // Запит за договорами (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = ``;
    if (nameFilterSearch != '') {
      request += `&name=${nameFilterSearch}`;
    };
    if (accountNameFilterSearch != '') {
      request += `&account.name=${accountNameFilterSearch}`;
    };
    // console.log("request", request);
    AxiosInstance.get(`${urlApi}?page=${p}${request}`  + defaultUserFilter).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || accountNameFilterSearch != "") {
      setDisabled(false);
    };
  }, [nameFilterSearch, accountNameFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = event => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  const handleAccountNameFilterChange = event => {
    setAccountNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setAccountNameFilterSearch("");
    setDisabled(true);
    getRowsTable();
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 200 },
    // { field: 'code1c', headerName: 'Код 1с', width: 200 },
    // { field: 'date_entered', headerName: 'Дата', width: 200 },
    { field: 'type', headerName: 'Тип', width: 100 },
    // { field: 'organization', headerName: 'Організація', width: 200 },
    {
      field: 'account.name', headerName: 'Контрагент', width: 200,
      renderCell: (params) => {
        return params.row.account.name;
      }
    },
    { field: 'dayDelay', headerName: 'Днів відтермінування', width: 170 },
    { field: 'maxBorg', headerName: 'Борг', width: 100 },
    { field: 'curency', headerName: 'Валюта', width: 100 },
    { field: 'type_payment', headerName: 'Спосіб оплати', width: 200 },


    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        // console.log("params action", params)
        // return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
        return <Link to={`${urlRouter}/${params.row.id}`}><CButton color="dark" variant="outline">Переглянути</CButton></Link>;
      }
    },
    // {
    //   field: "delete",
    //   headerName: "Видалити",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return <CButton color="danger" variant="outline" onClick={() => {
    //         Deleted(params.row)
    //       }
    //     }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
    //   }
    // },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CRow className="my-3 mx-2">
            <CCol md={9} className="me-auto">
              <CRow>
                <CCol md={4}>
                  <CFormInput placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2" onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} />
                </CCol>
                <CCol md={4}>
                  <CFormInput placeholder="Контрагент" id="accountNameFilterSearch" aria-label="Пошук" className="me-2" onChange={handleAccountNameFilterChange} value={accountNameFilterSearch} onKeyDown={keyDown} />
                </CCol>
              </CRow>
            </CCol>
            <CCol md={3} style={{ textAlign: "end" }}>
              <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled} >Пошук</CButton>
              <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
            </CCol>
          </CRow>

          <CCardHeader>
            <strong>Договори</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            {/* <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height="15" />Створити</CButton></Link>
            </p> */}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Agreements;
