import React from "react";
import Table from "react-bootstrap/Table";
import ProductTableItem from "./ProductTableItem";

// Таблиця з товарами для вибору для додавання до замовлення
const ChooseProductTable = (props) => {
    // console.log("props", props);
    return (
        <>
            {
                <Table striped bordered hover data-aos="fade-up"
                    className="orders-choose__table"
                >
                    <thead>
                        <tr>
                            <th className="orders-choose__th orders-choose__th-img">Img</th>
                            <th className="orders-choose__th">
                                <div>
                                    Назва
                                    {/* Відображати тільки для мобільної версії */}
                                    <div className="orders-choose__mobile">
                                        <div>code1c</div>
                                        <div>Штрихкод</div>
                                    </div>
                                </div>
                            </th>
                            <th className="orders-choose__th orders-choose__PC">code1c</th>
                            <th className="orders-choose__th orders-choose__PC">Штрихкод</th>
                            <th className="orders-choose__th orders-choose__th-price">
                                <div className="orders-choose__TC">
                                    <span>Ціна</span>
                                    <span>націнка</span>
                                    <span>собівартість</span>
                                </div>
                            </th>
                            <th className="orders-choose__th orders-choose__th-count">К-сть</th>
                            <th className="orders-choose__th orders-choose__th-action"></th>
                        </tr>
                    </thead>
                    <tbody className="order-table">
                        {
                            (props.rows != false) &&
                            props.rows.map((row) => {
                                return (
                                    <ProductTableItem key={row.id} product={row} setSelectedProductId={props.setSelectedProductId} addToOrder={props.addToOrder} products={props.products} />
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
        </>
    )
};

export default ChooseProductTable;