import React, { useEffect, useState } from 'react';
import AxiosInstance from '../../Axios';
import AsyncSelect from 'react-select/async';
import { CCardBody, CCardHeader, CCol, CRow, CButton, CForm, CInputGroup, CFormLabel, CFormInput } from '@coreui/react';
import ChooseProductTable from './ChooseProductTable';

// Вибрати товар зі списку
const ChooseProduct = (props) => {

    const urlApi = '/products';
    const page = 1;
    const itemsPerPage = 100;

    const [rows, setRows] = useState(false);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [categoryFilterSearch, setCategoryFilterSearch] = useState(''); // id вибраної категорії для запиту
    const [nameFilterSearch, setNameFilterSearch] = useState('');
    const [code1cFilterSearch, setCode1cFilterSearch] = useState('');
    const [barcodeFilterSearch, setBarcodeFilterSearch] = useState('');
    // Заблокувати кнопку фільрації якщо не введені символи для пошуку
    const [disabled, setDisabled] = useState(true);
    // Вибрана категорія
    const [selectedCategory, setSelectedCategory] = useState(null);

    // Завантажити товари при старті сторінки
    useEffect(() => {
        getRowsTable();
    }, []);

    // Запит за продуктами (+фільтр)
    const getRowsTable = (p = 1) => {
        let request = `&itemsPerPage=${itemsPerPage}`;
        if (categoryFilterSearch != '') {
            request += `&category.id=${categoryFilterSearch}`;
        };
        if (nameFilterSearch != '') {
            request += `&name=${nameFilterSearch}`;
        };
        if (code1cFilterSearch != '') {
            request += `&code1c=${code1cFilterSearch}`;
        };
        if (barcodeFilterSearch != '') {
            request += `&barcode=${barcodeFilterSearch}`;
        };
        // console.log("request", request);
        AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
            setRows(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
        });
    };

    // // Пагінація, наступна сторінка з товарами
    // const handlePageChange = (page) => {
    //     getRowsTable(page + 1);
    // };

    // Завантажити категорії
    const loadCategories = () => {
        return AxiosInstance.get('/categories', { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };

    // Вибрана категорія для відображення в полі пошуку та подальшій передачі для фільтревання товарів
    useEffect(() => {
        if (selectedCategory != null) {
            // console.log("selectedCategory", selectedCategory);
            setCategoryFilterSearch(selectedCategory.value);
        };
    }, [selectedCategory]);

    // Після вибору категорії зробити запит за товарами категорії та оновити товари для вибору
    useEffect(() => {
        getRowsTable();
    }, [categoryFilterSearch]);

    // Функція для вибору категорії з списку
    const handleChange = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
    };

    // Заблокувати кнопку фільрації якщо не введені символи для пошуку
    const disabledButton = (event) => {
        if (event.target.value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        };
    };

    // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
    useEffect(() => {
        if (categoryFilterSearch != "" || nameFilterSearch != "" || code1cFilterSearch != "" || barcodeFilterSearch != "") {
            setDisabled(false);
        };
    }, [categoryFilterSearch, nameFilterSearch, code1cFilterSearch, barcodeFilterSearch]);

    // ----- Start handleFilterChange -----
    const handleNameFilterChange = (event) => {
        setNameFilterSearch(event.target.value);
        disabledButton(event);
    };

    const handleCode1cFilterChange = (event) => {
        setCode1cFilterSearch(event.target.value);
        disabledButton(event);
    }

    const handleBarcodeFilterChange = (event) => {
        setBarcodeFilterSearch(event.target.value);
        disabledButton(event);
    };
    // ----- End handleFilterChange -----

    // Фільтрування по категорії, name, code1c та barcode
    const handleFilterSearch = (event) => {
        // console.log("handleFilterSearch event", event)
        event.preventDefault();
        getRowsTable();
    };

    // Скинути фільтр
    const handleFilterReset = () => {
        setCategoryFilterSearch("");
        setSelectedCategory(null);
        setNameFilterSearch("");
        setCode1cFilterSearch("");
        setBarcodeFilterSearch("");
        setDisabled(true);
        getRowsTable();
    };


    // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
    const keyDown = (event) => {
        // Виконати пошук
        if (event.key === 'Enter' && !disabled) {
            event.preventDefault();
            handleFilterSearch(event);
        };
        // Скинути пошук
        if (event.key === 'Escape') {
            event.preventDefault();
            handleFilterReset(event);
        };
    };

    return (
        <>
            <CRow>
                <CCol xs={12}>
                    <CRow className="my-3 mx-2">
                        <CCol sm={6}>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadCategories}
                                defaultOptions
                                value={selectedCategory}
                                onChange={handleChange}
                                className="flex-grow-1 me-1 mb-3"
                                onKeyDown={keyDown}
                            />
                        </CCol>
                        <CCol sm={6}>
                            <CFormInput placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-1 mb-3" onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} />
                        </CCol>
                        <CCol xxl={3} lg={4} md={3} sm={4}>
                            <CFormInput placeholder="code1c" id="code1cFilterSearch" aria-label="Пошук" className="me-1 mb-3" onChange={handleCode1cFilterChange} value={code1cFilterSearch} onKeyDown={keyDown} />
                        </CCol>
                        <CCol xxl={3} lg={4} md={3} sm={4}>
                            <CFormInput placeholder="Штрих-код" id="barcodeFilterSearch" aria-label="Пошук" className="me-1 mb-3" onChange={handleBarcodeFilterChange} value={barcodeFilterSearch} onKeyDown={keyDown} />
                        </CCol>
                        <CCol xxl={6} lg={4} md={6} sm={4} style={{ textAlign: "end" }}>
                            <CButton className="me-1" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                            <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                        </CCol>
                    </CRow>
                    <div style={{ height: "400px", width: '100%', overflow: "auto" }}>
                        <ChooseProductTable rows={rows} setSelectedProductId={props.setSelectedProductId} addToOrder={props.addToOrder} products={props.products} />
                    </div>
                </CCol>
            </CRow>
        </>
    )
};

export default ChooseProduct;