import { useState, useEffect } from "react";
import { cilBasket, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Конкретний продукт у вигляді таблиці
const ProductTableItem = (props) => {
    // console.log("ProductTableItem props", props);
    // Кількість товару
    const [counter, setCounter] = useState(1);
    // Зображення товару
    const cartOrderUrl = (props.product.media != undefined && props.product.media.length > 0) ? `${process.env.REACT_APP_SERVER_URL}${props.product.media[0].contentUrl}` : "";
    // Ціна одиниці товару
    const productPrice = (props.product != undefined && props.product.price != undefined) ? props.product.price : '0';
    // Собівартість за штуку
    const productPriceIncome = (props.product != undefined && props.product.priceIncome != undefined) ? props.product.priceIncome : '0';
    // Націнка за товар
    const productMarkUp = (productPriceIncome == 0) ? 0 : (productPrice - productPriceIncome);
    // Чи даний товар є в списку замовлень
    const [added, setAdded] = useState(false);

    // Відслідковувати зміну додавання/видалення з кошика замовлень
    useEffect(() => {
        let isInArray = false;
        if (props.products != false) {
            props.products.some(el => {
                // Порівняти id - чи є в кошику замовлень
                if (el.product.id === props.product.id) {
                    isInArray = true
                };
            });
        };
        setAdded(isInArray);
    }, [props.products]);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    };
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let count = event.target.value === "" ? 1 : parseFloat(event.target.value);
        setCounter(count);
    };

    // Додавання товару до замовлення
    const handleAddToOrder = (product) => {
        const orderCount = { ...product, count: counter };
        props.addToOrder(orderCount);
    };

    return (
        <tr>
            <td className="orders-choose__td-img">
                <img src={cartOrderUrl} className="orders-choose__img" />
            </td>
            <td className="orders-choose__name" onClick={() => props.setSelectedProductId(props.product.id)}>
                <div>
                    {props.product.name}
                    {/* Відображати тільки для мобільної версії */}
                    <div className="orders-choose__mobile">
                        <div className="orders-choose__text">{props.product.code1c}</div>
                        <div className="orders-choose__text">{props.product.barcode}</div>
                    </div>
                </div>
            </td>
            <td className="orders-choose__text orders-choose__PC">{props.product.code1c}</td>
            <td className="orders-choose__text orders-choose__PC">{props.product.barcode}</td>
            <td className="orders-choose__text">
                <div className="orders-choose__TC">
                    <span>{productPrice}</span>
                    <span>{productMarkUp}</span>
                    <span>{productPriceIncome}</span>
                </div></td>
            <td className="orders-choose__count">
                <button className='orders-choose__count-button1 me-1 text-center' onClick={productDecrement}>-</button>
                <input className='orders-choose__count-input me-1 text-center' type="text" pattern="[0-9]*" value={counter} onChange={inputChange} />
                <button className='orders-choose__count-button2' onClick={productIncrement}>+</button>
            </td>
            <td>
                {
                    (!added) ?
                        <CIcon icon={cilBasket} customClassName="nav-icon" height={18} onClick={() => handleAddToOrder(props.product)} style={{ cursor: "pointer" }} />
                        :
                        <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={18} style={{ color: "green" }} />
                }
            </td>
        </tr>
    )

};

export default ProductTableItem;