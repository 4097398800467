import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { CButton, CForm, CFormInput, CFormLabel, CFormTextarea, CInputGroup, CInputGroupText } from '@coreui/react';
// import RenderInputFields from 'src/components/BuilderForms/RenderInputFields';
import RenderInputFields from 'src/components/builderForms/RenderInputFields';
import AddInputType from 'src/components/builderForms/AddInputType';
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert';

const urlApi_1 = '/forms';
const urlApi_2 = '/form_fields';
const urlRouter = '/builder-forms';

export async function LoaderBuilderForms({ params }) {
    if (Object.keys(params).length != 0) {
        let res = await AxiosInstance.get(`${urlApi_1}/${params.pid}`).then((response) => {
            return response;
        });
        return res;
    }
    return false;
};

const formReducer = (state, event) => {
    return {
        ...state,
        [event.target.name]: event.target.value
    }
};

// Компонент для створення/оновлення форм
const BuilderForms = () => {

    const { data } = useLoaderData();
    const navigate = useNavigate();

    // Додані поля взяті з API
    const [dataCreatedFields, setDataCreatedFields] = useState([]);
    // Дані про форму, які підуть на сервер
    const [formData, setFormData] = useState({
        name: (data != undefined) ? data.name : "",
        description: (data != undefined) ? data.description : "",
        sendMailAnswet: (data != undefined) ? data.sendMailAnswet : "",
        formFields: [
            // { "label": "string", "type": "string", "defaultValue": "string", "name": "string" }
        ]
    });

    useEffect(() => {
        // console.log('dataCreatedFields', dataCreatedFields);

        // Для передачі на сервер
        setFormData({
            ...formData,
            formFields: dataCreatedFields,
        });
    }, [dataCreatedFields]);

    // Запит за полями форми
    useEffect(() => {
        // console.log('data', data);

        if (data !== undefined && data.id !== undefined) {
            AxiosInstance.get(`${urlApi_2}?form.id=${data.id}`).then((response) => {
                // console.log('get fields response', response);
                if (response.status == 200) {
                    setDataCreatedFields(response.data['hydra:member']);
                } else {
                    alert("Error");
                }
            });
        };
    }, [data]);

    // Дані, які відправляться на API
    // useEffect(() => {
    //     console.log('formData', formData);
    // }, [formData]);

    // Функція для змінити значення полів
    const handleChange = (value, name) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Відправити дані форми на API
    const handleSubmit = event => {
        event.preventDefault();

        // Запит для оновлення форми
        if (data != undefined) {
            AxiosInstance.put(`${urlApi_1}/${data.id}`, formData).then((response) => {
                // console.log('put response', response);
                if (response.status == 200) {
                    alert("Дані оновлено");
                };
                navigate(`${urlRouter}/update/${data.id}`);
            });
        } else {
            // Запит для створення форми
            AxiosInstance.post(urlApi_1, formData).then((response) => {
                // console.log('post response', response);
                if (response.status == 201) {
                    alert("Відправлено");
                    return navigate(`${urlRouter}/update/${response.data.id}`);
                } else {
                    alert("Error");
                }
            });
        };
    };

    // Функція для запиту даних форми
    const getFormsData = () => {
        AxiosInstance.get(`${urlApi_1}/${data.id}`).then((response) => {
            // console.log('get response', response);
            navigate(`${urlRouter}/update/${data.id}`);
        });
    };

    // Функція для видалення поля
    const deleteField = (id) => {
        // console.log('id', id);
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        AxiosInstance.delete(`${urlApi_2}/${id}`).then((response) => {
                            // console.log('get fields response', response);
                            if (response.status == 204) {
                                alert("Поле видалено");
                                // Оновити дані форми
                                getFormsData();
                            } else {
                                alert("Error");
                            }
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    return (
        <>
            {
                (data !== undefined) &&
                <>
                    <AddInputType formId={data.id} getFormsData={getFormsData} />
                    <hr />
                </>
            }

            <div className='row'>
                <div className='col-4 mb-3'>
                    <CForm onSubmit={handleSubmit}>
                        <div className='mb-3'>
                            <CFormInput
                                id="exampleFormControlInput1"
                                label="Назва форми"
                                placeholder="Назва форми"
                                value={formData.name}
                                onChange={(e) => { handleChange(e.target.value, 'name') }} required
                            // value="test"
                            // text="Must be 8-20 characters long."
                            // aria-describedby="exampleFormControlInputHelpInline"
                            />
                        </div>
                        

                        <div className='mb-3'>
                            <CFormTextarea
                                id="exampleFormControlTextarea2"
                                label="Опис форми"
                                rows={3}
                                placeholder="Опис форми"
                                value={formData.description}
                                onChange={(e) => { handleChange(e.target.value, 'description') }} required
                            // text="Must be 8-20 words long."
                            ></CFormTextarea>
                        </div>

                        <div className='mb-3'>
                            <CFormInput
                                id="exampleFormControlInput3"
                                label="E-mail - на який відправлятимуться сповіщення"
                                placeholder="E-mail"
                                value={formData.sendMailAnswet}
                                onChange={(e) => { handleChange(e.target.value, 'sendMailAnswet') }} required
                            // value="test"
                            // text="Must be 8-20 characters long."
                            // aria-describedby="exampleFormControlInputHelpInline"
                            />
                        </div>
                        <CButton type="submit" color="success">Зберегти</CButton>
                    </CForm>
                </div>

                <div className='col-8'>
                    {
                        (formData.formFields.length > 0) &&
                        <RenderInputFields formFields={formData.formFields} deleteField={deleteField} getFormsData={getFormsData} />
                    }
                </div>
            </div>
        </>
    );
};

export default BuilderForms;